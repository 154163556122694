import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import { widState } from "../../../Store/workdriveState";
import { useSnapshot } from "valtio";
import axios from "axios";
import { useEffect } from "react";
import ClipboardCopy from "../../RelatedList/srcfolder/components/WorkDrive/Modals/ClipboardCopy";
const ZOHO = window.ZOHO;

const customHeading = {
  fontWeight: 500,
  fontSize: "18px",
  color: "rgba(0, 0, 0, 0.87)",
};
const customDescription = {
  fontSize: "16px",
  color: "rgba(0, 0, 0, 0.6)",
};

export default function DeleteAction({
  moduleName,
  fieldOptions,
  setPage,
  existingData,
  dataCenterurl,
}) {
  const snap = useSnapshot(widState);
  const [fieldApiName, setFieldApiName] = useState(null);
  const [finalSaveLoading, setFinalSaveLoading] = useState(false);

  useEffect(() => {
    const prevSettings = existingData?.setting_schema
      ? JSON.parse(existingData?.setting_schema)
      : {};

    if (prevSettings?.fieldApiName) {
      setFieldApiName(prevSettings?.fieldApiName);
    }
  }, [existingData]);

  const handleSave = async (event) => {
    event.preventDefault();
    setFinalSaveLoading(true);
    let data = {
      moduleApiName: moduleName,
      fieldApiName: fieldApiName,
      settingType: "relatedList",
    };

    const insertedData = await axios.request({
      url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`,
      method: "POST",
      data: {
        settingId: widState?.settingId || "",
        settingSchema: data,
        showInRelatedList: false,
        moduleApiName: moduleName,
        settingType: "workflow",
      },
      headers: {
        orgid: widState.orgId,
        apikey: widState.apiKey,
        datacenterurl: widState.dataCenterUrl,
        connname: "easygoogledriveforcrm",
      },
    });

    const settingId = insertedData?.data?.data?.setting_id || "";

    if (settingId !== "") {
      await ZOHO.CRM.ACTION.setConfig({
        settingId: settingId,
        orgid: snap.orgId,
        datacenterurl: widState.dataCenterUrl,
        actionType: "Delete Action",
        // apikey: snap.apiKey,
        connname: "easygoogledriveforcrm",
      });
    }
    setFinalSaveLoading(false);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: existingData?.setting_id && "space-between",
          gap: 2,
          alignItems: "center",
          mx: 3,
          my: 1,
        }}
      >
        {setPage && (
          <ArrowBackIcon
            sx={{ cursor: "pointer" }}
            onClick={() => setPage(null)}
          />
        )}
        <Typography sx={{ fontSize: "24px", fontWeight: 500 }}>
          Delete Folder
        </Typography>

        {existingData?.setting_id ? (
          <Box
            sx={{
              mt: 1.1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Typography sx={{ fontWeight: "medium" }}>Setting Id:</Typography>
            <Typography sx={{ ml: 0.3, fontSize: 14, mt: 0.2 }}>
              {existingData?.setting_id}
            </Typography>
            <ClipboardCopy
              color={"transparent"}
              copyText={existingData?.setting_id}
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Box sx={{ mx: 3 }}>
        <br />
        <Typography sx={customHeading}>
          {moduleName} Field Name where Root Folder is stored
        </Typography>
        {/* <Divider /> */}
        <br />
        <Typography sx={customDescription}>
          When workflow executes it will delete Folder and Sub Folders from
          Google Drive based on Provided Root Folder Field Name. Once it is
          deleted, you can not retrieve the Data again.
          <br />
          <br />
          <strong>
            Delete Folder will not work on Delete Workflow as we dont have
            access to Root Folder id Stored in the Record after Record is
            Deleted. Sugested to use this on Edit Workflow.
          </strong>
        </Typography>
        <br />
        <Typography sx={{ mb: 2 }}>
          Select {moduleName} Field Name for Folder ID
          {/* Please Select The Field Name (Single Line Field) Where Folder Id Will
          Be Saved? */}
        </Typography>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          value={
            fieldApiName?.display_name
              ? {
                  field_label: fieldApiName?.display_name,
                }
              : null
          }
          options={fieldOptions}
          getOptionLabel={(option) => option.field_label}
          onChange={(e, v) => {
            if (v) {
              setFieldApiName({
                display_name: v.field_label,
                api_name: v.api_name,
              });
            } else {
              setFieldApiName(null);
            }
          }}
          size="small"
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
      <Box sx={{ position: "fixed", bottom: 14, right: 10 }}>
        <Button
          onClick={() => {
            setFieldApiName(null);
          }}
          // disabled={step === 5 ? false : true}
          size="small"
          sx={{ width: 100, mr: 1.5 }}
          variant="outlined"
        >
          Reset
        </Button>
        <Button
          onClick={handleSave}
          disabled={fieldApiName ? false : true}
          size="small"
          sx={{ width: 100 }}
          variant="contained"
        >
          {finalSaveLoading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            "Save"
          )}
        </Button>
      </Box>
    </Box>
  );
}
