import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid, Typography } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { useSnapshot } from "valtio";
import { widState } from "../../../store/workdriveState";
import * as ApiCall from "../../../../../API/ApiCalling";
import useFileNameLength from "../CustomHooks/useFileNameLength";
import ModalFile from "../Modals/ModalFile";
import "./../css/project_folder.css";
import axios from "axios";

const CustomImage = styled("img")(({ theme }) => ({
  height: "145px",
  minWidth: "100%",
  objectFit: "fill",
  backgroundSize: "cover",
  "z-index": -1,
}));

const BoxThumb = styled("Box")(({ theme }) => ({
  minHeight: "100%",
  minWidth: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

// const BoxThumb = styled("Box")(({ theme }) => ({
//   minHeight: "75%",
//   minWidth: "100%",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   backgroundColor: "#F8F8F8",
//   padding: "31px 0px",
// }));

const useStyles = makeStyles({
  fileroot: {
    border: "1px solid #BABEC2",
    borderRadius: "5px",
    minHeight: "180px",
    "&:hover": {
      boxShadow: "0 0 1px 1px #E0E0E0",
      transitionDuration: "0.3s",
    },
    "&:focus": {
      border: "2px solid #28B294",
    },
  },
  item: {
    textAlign: "left !important",
  },

  image: {
    width: "200px",
    height: "150px",
  },
  details: {
    margin: "0 auto",
    paddingTop: "5px",
  },
});

export default function FileImageCard({
  file,
  connname,
  orgid,
  apikey,
  settingId,
  datacenterurl,
  settingUrl,
}) {
  const [open, setOpen] = React.useState(false);
  const snap = useSnapshot(widState);
  const [profileImage, setProfileImage] = React.useState();
  const [thumbnailImage, setThumbnailUrl] = React.useState();

  const [len, setLen] = React.useState(16);
  let { nameLen } = useFileNameLength(file?.name);

  React.useEffect(() => {
    setLen(nameLen);
  }, [nameLen]);

  React.useEffect(() => {
    if (!file || file?.thumbnail == "") {
      setThumbnailUrl("error");
      return;
    }

    const fetchThumbnail = async () => {
      try {
        if (
          widState?.settingData?.[settingId]?.thumbnailUrls?.hasOwnProperty(
            file?.id
          )
        ) {
          setThumbnailUrl(
            (prev) =>
              widState.settingData?.[settingId]?.thumbnailUrls?.[file?.id]
          );
        } else {
          const response = await ApiCall.getThumbnailData(
            file,
            connname,
            orgid,
            apikey,
            datacenterurl,
            settingUrl
          );

          if (response?.data?.size <= 100) {
            setThumbnailUrl("error");
          }
          var blodData = new Blob([response.data]);
          var urlData = URL.createObjectURL(blodData);
          widState.setThumbNail(settingId, file, urlData);
          setThumbnailUrl((prev) => urlData);
        }
      } catch (error) {
        setThumbnailUrl("error");
      }
    };
    fetchThumbnail();
  }, [file?.id]);

  const getUserImage = async (file) => {
    let res = await ApiCall.getImageResponse(
      file,
      connname,
      orgid,
      apikey,
      datacenterurl,
      settingUrl
    );

    // //
    var file = new Blob([res.data]);
    var fileURL = URL.createObjectURL(file);
    // //
    setProfileImage(fileURL);
  };

  const handleDisplay = (thumbnailImage) => {
    if (!thumbnailImage) {
      return (
        <BoxThumb>
          <CircularProgress color="secondary" />
        </BoxThumb>
      );
    } else if (thumbnailImage === "error") {
      return (
        <BoxThumb sx={{ backgroundColor: "#F8F8F8" }}>
          <DescriptionOutlinedIcon sx={{ fontSize: "80px" }} />
        </BoxThumb>
      );
    } else if (thumbnailImage) {
      return <CustomImage src={thumbnailImage} />;
    }
  };

  const handleClickOpen = (file) => {
    // //
    getUserImage(file);
    setOpen(true);
  };

  const handleClose = () => {
    // //
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <Grid>
      <Grid
        item
        container
        tabIndex="1"
        className={classes.fileroot}
        onClick={() => handleClickOpen(file)}
      >
        {handleDisplay(thumbnailImage)}
        <Grid
          className={classes.details}
          sx={{
            display: "inline",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            backgroundColor: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          {len && (
            <Typography variant="h6">
              {`${file?.name?.substr(0, len)}...`}
            </Typography>
          )}
        </Grid>
      </Grid>
      <ModalFile
        open={open}
        setOpen={setOpen}
        profileImage={profileImage}
        file={file}
        settingId={settingId}
        connname={connname}
        orgid={orgid}
        apikey={apikey}
        datacenterurl={datacenterurl}
        settingUrl={settingUrl}
      />
    </Grid>
  );
}
