import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { makeStyles } from "@mui/styles";
import * as ApiCall from "../../../../../API/ApiCalling";
import ModalPdf from "../Modals/ModalPdf";

import { useMediaQuery } from "@mui/material";
import { useSnapshot } from "valtio";
import { widState } from "../../../store/workdriveState";
import useFileNameLength from "../CustomHooks/useFileNameLength";
import ModalFile from "../Modals/ModalFile";
import ModalImage from "../Modals/ModalImage";

const useStyles = makeStyles({
  grid: {
    border: "1px solid #E0E0E0",
    backgroundColor: "#e4f5ff",
    borderRadius: "5px",
    "&:hover": {
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      transitionDuration: "0.3s",
    },
  },
  item: {
    textAlign: "left !important",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxWidth: "1100px",
  width: "100%",
  height: "auto",
  transform: "translate(-50%, -50%)",
  outline: "none",
  background: "#ffffff",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  width: "100%",
  textAlign: "left",
  color: theme.palette.text.secondary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // backgroundColor: '#e4f5ff',
}));

function ListItems({
  setShowRightOption,
  file,
  settingId,
  orgid,
  connname,
  apikey,
  datacenterurl,
  settingUrl,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [profileImage, setProfileImage] = React.useState(null);
  const snap = useSnapshot(widState);

  const [len, setLen] = React.useState(16);
  let { nameLen } = useFileNameLength(file?.name);

  React.useEffect(() => {
    // // ;
    setLen(nameLen);
  }, [nameLen]);

  const getUserImage = async (file) => {
    if (
      widState.settingData?.[settingId]?.downloadUrls.hasOwnProperty(file?.id)
    ) {
      snap?.setLoading(false);
      setOpen((prev) => true);
      setProfileImage(
        (prev) => widState.settingData?.[settingId]?.downloadUrls?.[file?.id]
      );
    } else {
      snap?.setLoading(true);
      let res = await ApiCall.getImageResponse(
        file,
        connname,
        orgid,
        apikey,
        datacenterurl,
        settingUrl
      );

      var blobFile = new Blob([res.data]);
      var fileURL = URL.createObjectURL(blobFile);
      // // ;
      widState.setDownloadedImage(settingId, file, fileURL);
      setProfileImage((prev) => fileURL);
      snap?.setLoading(false);
      setOpen((prev) => true);
      // window.open(fileURL);
    }
  };

  const handleStart = (event, file) => {
    setShowRightOption((prev) => false);
    event.dataTransfer.setData(
      "moveData",
      JSON.stringify({
        fileName: file?.file?.name,
        dropFile: event.target.id,
        fileType: file?.file?.type,
        dropFileParentId: file?.file?.parent_id,
      })
    );

    setTimeout(() => {
      setShowRightOption((prev) => true);
    }, 1000);
  };

  const handleClickOpen = (file) => {
    // // ;
    getUserImage(file);
  };

  const handleClose = () => {
    // // ;
    snap?.setLoading(false);
    setOpen(false);
  };
  const matches = useMediaQuery("(min-width:1300px)");
  return (
    <Grid className={classes.grid} container>
      <Item
        className={classes.item}
        wrap="nowrap"
        draggable="true"
        id={file.id}
        onDragStart={(e) => handleStart(e, { file: file })}
        onClick={() => {
          snap?.setLoading(true);
          handleClickOpen(file);
        }}
      >
        <Grid item>
          <Avatar
            sx={{
              width: 25,
              height: 25,
              mr: `${matches ? "10px" : "6px"}`,
              fontSize: `${matches ? "11px" : "10px"}`,
            }}
          >
            {file?.extn}
          </Avatar>
        </Grid>
        <Grid
          item
          xs
          sx={{
            display: "inline",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {/* {len && (
            <Typography sx={{ fontSize: "16px" }}>
              {`${file?.name.substr(0, len)}...`}
            </Typography>
          )} */}
          {file?.name?.length > len ? (
            <Typography sx={{ fontSize: "16px" }}>
              {`${file?.name?.substr(0, len)}...`}
            </Typography>
          ) : (
            <Typography sx={{ fontSize: "16px" }}>{file?.name}</Typography>
          )}
        </Grid>
      </Item>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ModalDecider
            open={open}
            setOpen={setOpen}
            profileImage={profileImage}
            file={file}
            settingId={settingId}
            connname={connname}
            orgid={orgid}
            apikey={apikey}
            datacenterurl={datacenterurl}
            settingUrl={settingUrl}
          />
        </Box>
      </Modal>
    </Grid>
  );
}

export default ListItems;

const ModalDecider = ({
  open,
  setOpen,
  profileImage,
  file,
  settingId,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl,
}) => {
  switch (file?.type) {
    case "image":
      return (
        <ModalImage open={open} setOpen={setOpen} profileImage={profileImage} />
      );
      break;
    case "pdf":
      return (
        <ModalPdf
          width="100%"
          alt=""
          open={open}
          setOpen={setOpen}
          profileImage={profileImage}
        />
      );
      break;
    default:
      return (
        <ModalFile
          width="100%"
          alt=""
          open={open}
          setOpen={setOpen}
          profileImage={""}
          file={file}
          datacenterurl={datacenterurl}
          settingId={settingId}
          connname={connname}
          orgid={orgid}
          apikey={apikey}
          settingUrl={settingUrl}
        />
      );
      break;
  }
};
