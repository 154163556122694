import { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import CommonComponent from "./CommonComponent";

import {
  Alert,
  AlertTitle,
  Box,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";

import { useTableSearch } from "../CustomHooks/UseTableSearch";
import CustomSeparator from "./BreadCumb";

import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import { useSnapshot } from "valtio";
import { widState } from "../../../store/workdriveState";
import * as ApiCall from "../../../../../API/ApiCalling";
import * as FileUploadResponse from "../Others/FileUploadResponse";
import SnackAlert from "../Others/SnackAlert";
import ModalFileUpload from "./../Modals/ModalFileUpload";
import empty_folder from "../../../../../../Images/no-items.png";
import easyPlugins from "../../../../../../Images/easypluginz-label.png";
import DetailsView from "./DetailsView";
import SearchDialog from "../Others/SearchDialog";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

function Response(props) {
  const {
    Entity,
    EntityId,
    fieldApiName,
    setFieldApiName,
    setRootFolderId,
    recordData,
    fields,
    isAdmin,
    settingId,
    post,
    setPost,
    orgid,
    connname,
    apikey,
    datacenterurl,
    settingUrl,
    searchVal,
    setSearchVal,
    handleClick,
    setDetailsViewPost,
  } = props;
  const snap = useSnapshot(widState);
  const [snackOpen, setSnackOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchedItem, setSearchedItem] = useState([]);
  let moveCopyItem = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const { filteredData, loading } = useTableSearch({
    searchVal,
    retrieve: post,
  });

  function handleCloseModal() {
    setOpen((prev) => false);
  }

  function moveCopyData(e, data) {
    // widState.setSnackbar({
    //   bool: true,
    //   type: "Success",
    //   message: "File/Folder copied to the clipboard",
    // });
    widState.setPasteOpen(true);
    moveCopyItem.current = data;
    snap.setSnackbar({
      bool: true,
      type: "success",
      message: "Item copied to clipboard",
    });
    // // ;
    // // ;
  }

  async function pasteData(e, data) {
    // // ;
    if (
      moveCopyItem?.current?.type &&
      moveCopyItem?.current?.file?.id !== data?.pasteFile?.id &&
      data?.pasteFile?.type === "folder"
    ) {
      snap?.setLoading(true);
      if (moveCopyItem?.current?.type === "copy") {
        // // ;
        try {
          const res = await ApiCall.copyFile(
            data?.pasteFile,
            moveCopyItem?.current?.file?.id,
            connname,
            orgid,
            apikey,
            datacenterurl,
            settingUrl
          );

          if (res?.data) {
            // // ;
            if (data?.pastePost) {
              let destinationData = [
                FileUploadResponse.changeParent(
                  moveCopyItem?.current?.file,
                  data?.pasteFile?.id
                ),
                ...data?.pastePost,
              ];

              widState?.setAddItemSettingData(
                settingId,
                data?.pasteFile?.id,
                destinationData
              );
            }

            // setSnackOpen(true);
            snap.setSnackbar({
              bool: true,
              type: "success",
              message: "Item copied successfully",
            });
          } else {
            snap.setSnackbar({
              bool: true,
              type: "error",
              message: res?.error?.message,
            });

            // alert(
            //   "Something went wrong and Copy folder is not allowed for google drive"
            // );
          }

          // setPost([...post]);
        } catch (error) {
          // // ;
        }
      } else if (moveCopyItem?.current?.type === "move") {
        // let tempView = snap?.view;
        // if (tempView) {
        //   widstate?.setView("list");
        // }

        // // ;
        try {
          // dropFileParentId
          const res = await ApiCall.moveFile(
            data?.pasteFile,
            moveCopyItem?.current?.file,
            connname,
            orgid,
            apikey,
            datacenterurl,
            settingUrl
          );
          if (res?.data) {
            let xArray = post?.filter(
              (file) => file.id != moveCopyItem?.current?.file?.id
            );

            let parent_id = moveCopyItem?.current?.file?.parent_id;
            let filteredData = moveCopyItem?.current?.parentFiles?.filter(
              (file) => file?.id != moveCopyItem?.current?.file?.id
            );
            widState?.setAddItemSettingData(settingId, parent_id, filteredData);

            if (data?.pastePost) {
              let destinationData = [
                FileUploadResponse.changeParent(
                  moveCopyItem?.current?.file,
                  data?.pasteFile?.id
                ),
                ...data?.pastePost,
              ];
              widState?.setAddItemSettingData(
                settingId,
                data?.pasteFile?.id,
                destinationData
              );
            }

            setPost((prev) => xArray);
            // if (tempView) {
            //   widstate?.setView("grid");
            // }
            // setSnackOpen(true);
            snap.setSnackbar({
              bool: true,
              type: "success",
              message: "Item moved successfully",
            });
          } else {
            // if (tempView) {
            //   widstate?.setView("grid");
            // }
            snap.setSnackbar({
              bool: true,
              type: "error",
              message:
                res?.error?.message ||
                "Something went wrong and Copy folder is not allowed for google drive",
            });
          }
        } catch (error) {
          // // ;
          snap.setSnackbar({
            bool: true,
            type: "error",
            message: error?.data ? error?.data : error,
          });
        }
        snap?.setPasteOpen(false);
      }

      snap?.setLoading(false);
    } else {
      // // ;
    }
  }

  async function pasteDatainDetailsView(e, data) {
    if (
      moveCopyItem?.current?.type &&
      moveCopyItem?.current?.file?.id !== data?.pasteFile?.id &&
      data?.pasteFile?.type === "folder"
    ) {
      snap?.setLoading(true);
      if (moveCopyItem?.current?.type === "copy") {
        try {
          const res = await ApiCall.copyFile(
            data?.pasteFile,
            moveCopyItem?.current?.file?.id,
            connname,
            orgid,
            apikey,
            datacenterurl,
            settingUrl
          );

          if (res?.data) {
            if (data?.pastePost) {
              let destinationData = [
                FileUploadResponse.changeParent(
                  moveCopyItem?.current?.file,
                  data?.pasteFile?.id
                ),
                ...data?.pastePost,
              ];

              widState?.setAddItemSettingData(
                settingId,
                data?.pasteFile?.id,
                destinationData
              );
            }

            // setSnackOpen(true);
            snap.setSnackbar({
              bool: true,
              type: "success",
              message: "Item copied successfully",
            });
          } else {
            snap.setSnackbar({
              bool: true,
              type: "error",
              message: res?.error?.message || "Error!! Couldn't move file",
            });

            // alert(
            //   "Something went wrong and Copy folder is not allowed for google drive"
            // );
          }
          // setPost([...post]);
        } catch (error) {}
      } else if (moveCopyItem?.current?.type === "move") {
        try {
          // dropFileParentId
          const res = await ApiCall.moveFile(
            data?.pasteFile,
            moveCopyItem?.current?.file,
            connname,
            orgid,
            apikey,
            datacenterurl,
            settingUrl
          );
          if (res?.data) {
            let xArray = JSON.parse(
              JSON.stringify(
                widState?.settingData?.[settingId]?.previousData?.[
                  data?.pasteFile?.parent_id
                ] || {}
              )
            )?.filter((file) => file.id != moveCopyItem?.current?.file?.id);

            let parent_id = moveCopyItem?.current?.file?.parent_id;
            let filteredData = JSON.parse(
              JSON.stringify(
                widState?.settingData?.[settingId]?.previousData?.[parent_id] ||
                  {}
              )
            )?.filter((file) => file?.id != moveCopyItem?.current?.file?.id);
            widState?.setAddItemSettingData(settingId, parent_id, filteredData);

            if (data?.pastePost) {
              let destinationData = [
                FileUploadResponse.changeParent(
                  moveCopyItem?.current?.file,
                  data?.pasteFile?.id
                ),
                ...data?.pastePost,
              ];
              widState?.setAddItemSettingData(
                settingId,
                data?.pasteFile?.id,
                destinationData
              );
            }

            setPost((prev) => xArray);
            // if (tempView) {
            //   snap.setViewSettingData(settingId, true);
            // }
            // setSnackOpen(true);
            snap.setSnackbar({
              bool: true,
              type: "success",
              message: "Item moved successfully",
            });
          } else {
            // if (tempView) {
            //   snap.setViewSettingData(settingId, true);
            // }
            snap.setSnackbar({
              bool: true,
              type: "error",
              message:
                "Something went wrong and Copy folder is not allowed for google drive",
            });
          }
        } catch (error) {
          snap.setSnackbar({
            bool: true,
            type: "error",
            message: error?.data ? error?.data : error,
          });
        }
        snap?.setPasteOpen(false);
      } else {
        snap.setSnackbar({
          bool: true,
          type: "error",
          message: "Error! Copy/Cut file or folder first",
        });
      }
    } else {
      snap.setSnackbar({
        bool: true,
        type: "error",
        message: "Error! Copy/Cut file or folder first",
      });
    }

    snap?.setLoading(false);
  }

  async function setBreadCrumbsUrl(file, data) {
    widState?.setBreadCrumbsSettingData(settingId, file);
    setPost(data);
    setSearchVal("");
  }

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    // accept: "image/*",
    onDrop: (acceptedFiles) => {
      // // ;
      setOpen(true);
      setFiles(
        acceptedFiles.map((file, index) => {
          if (file.type.split("/")[1] == "pdf")
            return Object.assign(file, {
              preview: URL.createObjectURL(file),
              special: true,
              specialType: "pdf",
              id: index,
            });
          else if (file.type.split("/")[0] == "image") {
            return Object.assign(file, {
              preview: URL.createObjectURL(file),
              special: false,
              specialType: "image",
              id: index,
            });
          } else if (file.type.split("/")[1] == "zip") {
            return Object.assign(file, {
              preview: URL.createObjectURL(file),
              special: false,
              specialType: "zip",
              id: index,
            });
          } else if (file.type.split("/")[1] == "msword") {
            return Object.assign(file, {
              preview: URL.createObjectURL(file),
              special: false,
              specialType: "msword",
              id: index,
            });
          } else {
            return Object.assign(file, {
              preview: URL.createObjectURL(file),
              special: false,
              specialType: "other",
              id: index,
            });
          }
        })
      );
    },
  });

  const removeFile = (deleteFile) => {
    // // ;
    setFiles(files.filter((file) => file.id != deleteFile.id));
  };

  const uploadFile = async (folderId) => {
    try {
      // ;
      // ;
      let fileUploadPromise = [];
      let lastIndex =
        widState?.settingData?.[settingId]?.breadCrumbs?.length - 1;
      let lastIndexId =
        widState?.settingData?.[settingId]?.breadCrumbs?.[lastIndex].id;
      snap?.setLoading(true);
      // TODO: To Rahul: Here modal loding is not set, Please fix this. Follow "uploadFile" props.
      handleCloseModal();
      const data = new FormData();
      files.forEach((file) => {
        data.append("file", file);
      });

      const response = await ApiCall.fileUploader(
        data,
        lastIndexId,
        connname,
        orgid,
        apikey,
        datacenterurl,
        settingUrl
      );

      // ;

      if (response?.error) {
      }
      let myCustomArray = [...response?.data, ...post];

      // setSnackOpen(true);
      snap.setSnackbar({
        bool: true,
        type: "success",
        message: "File uploaded successfully",
      });
      widState.setAddItemSettingData(settingId, lastIndexId, myCustomArray);
      setPost((prev) => myCustomArray);
      snap?.setLoading(false);
    } catch (error) {
      // ;
    }
    setFiles((prev) => []);
  };

  const thumbs = files.length ? (
    <ModalFileUpload
      files={files}
      orgid={orgid}
      connname={connname}
      apikey={apikey}
      datacenterurl={datacenterurl}
      settingUrl={settingUrl}
      removeFile={removeFile}
      open={open}
      setOpen={setOpen}
      handleCloseModal={handleCloseModal}
      uploadFile={uploadFile}
    />
  ) : (
    <></>
  );

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    snap.setSnackbar({
      bool: false,
      type: "success",
      message: "",
    });
  };

  const muiTheme = useTheme();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      {...getRootProps({ className: "dropzone disabled" })}
    >
      {filteredData && (
        <Grid item container>
          <CustomSeparator
            setSearchedItem={setSearchedItem}
            setSearchOpen={setSearchOpen}
            Entity={Entity}
            EntityId={EntityId}
            isAdmin={isAdmin}
            fieldApiName={fieldApiName}
            setFieldApiName={setFieldApiName}
            setRootFolderId={setRootFolderId}
            recordData={recordData}
            fields={fields}
            settingId={settingId}
            orgid={orgid}
            connname={connname}
            apikey={apikey}
            datacenterurl={datacenterurl}
            settingUrl={settingUrl}
            setBreadCrumbsUrl={setBreadCrumbsUrl}
            searchVal={searchVal}
            setSearchVal={setSearchVal}
            handleClickOpen={handleClickOpen}
            setPost={setPost}
            post={post}
            setSnackOpen={setSnackOpen}
          />
        </Grid>
      )}

      <input {...getInputProps()} />
      <div style={thumbsContainer}>{thumbs}</div>

      {widState?.view == "details" && (
        <DetailsView
          post={post}
          filteredData={filteredData}
          handleClick={handleClick}
          datacenterurl={datacenterurl}
          connname={connname}
          orgid={orgid}
          apikey={apikey}
          settingId={settingId}
          settingUrl={settingUrl}
          setDetailsViewPost={setDetailsViewPost}
          moveCopyData={moveCopyData}
          pasteDatainDetailsView={pasteDatainDetailsView}
        />
      )}

      {snap?.view != "details" && (
        <>
          {filteredData.length === 0 && !snap?.loading && (
            <Grid item fullheight justifyContent="center" sx={{ mt: 7 }}>
              <img src={empty_folder} alt="empty" width="100%" height={220} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "24px",
                  color: "rgba(0, 0, 0, 0.87)",
                  mt: 1,
                }}
              >
                <strong> The folder is empty!</strong>
              </Box>
            </Grid>
          )}

          {filteredData.length !== 0 && (
            <Grid
              container
              item
              xs={12}
              spacing={{ xs: 2, md: 3 }}
              sx={{ padding: "10px 20px" }}
            >
              <Grid item xs={12} sx={{ padding: "3px 0px" }}>
                {filteredData.filter((file) => file.type === "folder")
                  .length !== 0 && (
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      fontWeight: 500,
                      color: "#000000",
                      mb: 1,
                    }}
                  >
                    Folder
                  </Typography>
                )}

                <Grid container spacing={{ xs: 2, md: 3 }}>
                  {filteredData?.map((file, index) => {
                    if (file.type == "folder")
                      return (
                        <Grid item xs={6} sm={4} md={3} lg={2}>
                          <CommonComponent
                            settingId={settingId}
                            orgid={orgid}
                            connname={connname}
                            apikey={apikey}
                            datacenterurl={datacenterurl}
                            settingUrl={settingUrl}
                            file={file}
                            handleClick={handleClick}
                            setPost={setPost}
                            post={post}
                            setSnackOpen={setSnackOpen}
                            moveCopyData={moveCopyData}
                            pasteData={pasteData}
                          />
                        </Grid>
                      );
                  })}
                </Grid>
              </Grid>
              <Grid item xs={12} className="relatedlist-files-container">
                {filteredData.filter((file) => file.type !== "folder")
                  .length !== 0 && (
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    sx={{ textAlign: "left", fontWeight: 500, mb: 1 }}
                  >
                    Files
                  </Typography>
                )}
                <>
                  <>
                    {snap?.view == "list" && (
                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columnSpacing={{ xs: 2, sm: 2, md: 3 }}
                      >
                        {filteredData?.map((file, index) => {
                          if (file.type !== "folder")
                            return (
                              <Grid
                                item
                                xs={6}
                                sm={4}
                                md={3}
                                lg={2}
                                sx={{ cursor: "pointer" }}
                              >
                                <CommonComponent
                                  settingId={settingId}
                                  orgid={orgid}
                                  connname={connname}
                                  apikey={apikey}
                                  datacenterurl={datacenterurl}
                                  settingUrl={settingUrl}
                                  file={file}
                                  handleClick={handleClick}
                                  setPost={setPost}
                                  post={post}
                                  setSnackOpen={setSnackOpen}
                                  moveCopyData={moveCopyData}
                                  pasteData={pasteData}
                                />
                              </Grid>
                            );
                        })}
                      </Grid>
                    )}
                  </>
                  <>
                    {snap?.view == "grid" && (
                      <Grid container spacing={{ xs: 2, md: 4 }}>
                        {filteredData?.map((file, index) => {
                          if (file.type !== "folder")
                            return (
                              <Grid
                                item
                                xs={6}
                                sm={4}
                                md={3}
                                lg={2}
                                sx={{ cursor: "pointer" }}
                              >
                                <CommonComponent
                                  settingId={settingId}
                                  orgid={orgid}
                                  connname={connname}
                                  apikey={apikey}
                                  datacenterurl={datacenterurl}
                                  settingUrl={settingUrl}
                                  file={file}
                                  handleClick={handleClick}
                                  setPost={setPost}
                                  post={post}
                                  setSnackOpen={setSnackOpen}
                                  moveCopyData={moveCopyData}
                                  pasteData={pasteData}
                                />
                              </Grid>
                            );
                        })}
                      </Grid>
                    )}
                  </>
                </>
              </Grid>
              {/* <Box
                sx={{
                  position: "fixed",
                  bottom: 5,
                  right: 5,
                  zIndex: -99,
                }}
              >
                <img src={easyPlugins} alt="easy plugins logo" height={40} />
              </Box> */}
            </Grid>
          )}
        </>
      )}

      <SnackAlert
        duration={2000}
        snackOpen={snap?.snackbar?.open}
        handleCloseSnack={handleCloseSnack}
        severity={snap?.snackbar?.severity}
        message={snap?.snackbar?.message}
      />
    </Grid>
  );
}

export default Response;
