import SaveIcon from "@mui/icons-material/Create";
import Button from "@mui/material/Button";
import { Controller, useForm } from "react-hook-form";

import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import "./../css/coupon.css";

import { useSnapshot } from "valtio";
import { widState } from "../../../store/workdriveState";
import * as ApiCall from "../../../../../API/ApiCalling";
function NameDialog({
  open,
  setOpen,
  settingId,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl,
  handleClose,
  setPost,
  post,
}) {
  const snap = useSnapshot(widState);
  const { handleSubmit, control, reset } = useForm();

  const onSubmit = async (data) => {
    // //
    try {
      // setOpen((prev)=> false);
      snap.setLoading(true);
      let lastIndex = snap.settingData?.[settingId]?.breadCrumbs?.length - 1;
      let lastIndexId =
        snap.settingData?.[settingId]?.breadCrumbs?.[lastIndex].id;

      const response = await ApiCall.createFolder(
        lastIndexId,
        {
          name: data?.name,
        },
        connname,
        orgid,
        apikey,
        datacenterurl,
        settingUrl
      );

      if (response?.error) {
        setOpen((prev) => false);
        snap.setLoading(false);
        return;
      }

      let myCustomArray = [response?.data?.[0], ...post];
      // ;
      setPost((prev) => myCustomArray);
      widState.setAddItemSettingData(settingId, lastIndexId, myCustomArray);
      snap.setLoading(false);

      snap.setSnackbar({
        bool: true,
        type: "success",
        message: "Folder created successfully",
      });
    } catch (error) {
      snap.setLoading(false);
      snap.setSnackbar({
        bool: true,
        type: "error",
        message: error?.message || "Error!! Couldn't move file",
      });

      // alert(error);
    }
    reset();
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            minWidth: "400px", // Set your width here
            padding: "10px",
          },
        },
      }}
    >
      <DialogTitle sx={{ fontSize: "23px", fontWeight: 500 }}>
        Create Folder
      </DialogTitle>
      <DialogContent>
        {/* <label style={{fontSize: "18px",paddingBottom:"20px"}}></label> */}
        <p style={{ fontSize: "18px", fontWeight: 400 }}>Folder Name</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <TextField
                  id="standard-basic"
                  // label="folder name"
                  variant="outlined"
                  {...field}
                  fullWidth
                />
              </>
            )}
          />
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              sx={{ marginTop: "10px", mr: 2, color: "#1850A0" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              sx={{ marginTop: "10px", bgcolor: "#1976D2" }}
              type="submit"
              variant="contained"
            >
              Create
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default NameDialog;
