import { Grid, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { experimentalStyled as styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { useSnapshot } from "valtio";
import { widState } from "../../../store/workdriveState";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import * as ApiCall from "../../../../../API/ApiCalling";
import useFileNameLength from "../CustomHooks/useFileNameLength";
import UseThumbnailFetch from "../CustomHooks/UseThumbnailFetch";
import "./../css/project_folder.css";
import ModalImage from "./../Modals/ModalImage";

const CustomImage = styled("img")(({ theme }) => ({
  height: "145px",
  minWidth: "100%",
  objectFit: "fill",
  backgroundSize: "cover",
  "z-index": -1,
}));

const BoxThumb = styled("Box")(({ theme }) => ({
  minHeight: "100%",
  minWidth: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const useStyles = makeStyles({
  fileroot: {
    border: "1px solid #BABEC2",
    borderRadius: "5px",

    minHeight: "180px",
    "&:hover": {
      boxShadow: "0 0 1px 1px #E0E0E0",
      transitionDuration: "0.3s",
    },
    "&:focus": {
      border: "2px solid #28B294",
    },
  },

  details: {
    margin: "0 auto",
  },
  image: {
    width: "200px",
    height: "150px",
  },
});

export default function DisplayImage({
  file,
  settingId,
  orgid,
  connname,
  apikey,
  datacenterurl,
  settingUrl,
}) {
  // file = JSON.parse(JSON.stringify(file))
  const [open, setOpen] = React.useState(false);
  const snap = useSnapshot(widState);
  const [profileImage, setProfileImage] = React.useState();
  const [thumbnailImage, setThumbnailUrl] = React.useState();

  React.useEffect(() => {
    if (!file) return;
    const fetchThumbnail = async () => {
      try {
        if (
          widState?.settingData?.[settingId]?.thumbnailUrls?.hasOwnProperty(
            file?.id
          )
        ) {
          setThumbnailUrl(
            (prev) =>
              widState.settingData?.[settingId]?.thumbnailUrls?.[file?.id]
          );
        } else {
          const response = await ApiCall.getThumbnailData(
            file,
            connname,
            orgid,
            apikey,
            datacenterurl,
            settingUrl
          );

          var blodData = new Blob([response.data]);
          var urlData = URL.createObjectURL(blodData);
          widState.setThumbNail(settingId, file, urlData);
          setThumbnailUrl((prev) => urlData);
        }
      } catch (error) {
        setThumbnailUrl("error");
      }
    };
    fetchThumbnail();
  }, [file?.id]);

  const handleDisplay = (thumbnailImage) => {
    if (!thumbnailImage) {
      return (
        <BoxThumb>
          <CircularProgress color="secondary" />
        </BoxThumb>
      );
    } else if (thumbnailImage === "error") {
      return (
        <BoxThumb sx={{ backgroundColor: "#F8F8F8" }}>
          <DescriptionOutlinedIcon sx={{ fontSize: "80px" }} />
        </BoxThumb>
      );
    } else if (thumbnailImage) {
      return <CustomImage src={thumbnailImage} />;
    }
    // return (
    //   <BoxThumb>
    //     <CircularProgress />
    //   </BoxThumb>
    // );
  };
  const [len, setLen] = React.useState(16);
  let { nameLen } = useFileNameLength(file?.name);

  React.useEffect(() => {
    setLen(nameLen);
  }, [nameLen]);

  const getUserImage = async (file) => {
    if (
      widState?.settingData?.[settingId]?.downloadUrls?.hasOwnProperty(file?.id)
    ) {
      snap?.setLoading(false);
      setOpen((prev) => true);
      setProfileImage(
        (prev) => widState?.settingData?.[settingId]?.downloadUrls?.[file?.id]
      );
    } else {
      let res = await ApiCall.getImageResponse(
        file,
        connname,
        orgid,
        apikey,
        datacenterurl,
        settingUrl
      );

      var blobFile = new Blob([res.data]);
      var fileURL = URL.createObjectURL(blobFile);
      //
      widState.setDownloadedImage(settingId, file, fileURL);
      setProfileImage((prev) => fileURL);
      setOpen((prev) => true);
    }

    // window.open(fileURL);
  };

  const handleClickOpen = (file) => {
    //
    snap?.setLoading(true);
    getUserImage(file);
  };

  const classes = useStyles();

  return (
    <Grid container>
      <Grid
        item
        container
        tabIndex="1"
        className={classes.fileroot}
        onClick={() => handleClickOpen(file)}
      >
        {handleDisplay(thumbnailImage)}
        <Grid
          className={classes.details}
          sx={{
            display: "inline",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            backgroundColor: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          {len && (
            <Typography variant="h6">
              {`${file?.name?.substr(0, len)}...`}
            </Typography>
          )}
        </Grid>
      </Grid>

      <ModalImage
        open={open}
        setOpen={setOpen}
        profileImage={profileImage}
        settingId={settingId}
      />
    </Grid>
  );
}
