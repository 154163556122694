import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import * as ApiCall from "./../../API/ApiCalling";
import { useEffect, useState } from "react";
import CreateNewFolderModal from "./CreateNewFolderModal";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import no_folder from "../../../Images/no-items.png";

// FIXME fix zuid

const selectedStyle = {
  display: "flex",
  alignItems: "center",
  border: "1.5px solid #1F6DDE",
  color: "#1F6DDE",
  borderRadius: "4px",
  cursor: "pointer",
  height: "2.3rem",
  "&:hover": { boxShadow: 1 },
};

const notSelectedStyle = {
  display: "flex",
  alignItems: "center",
  border: "1px solid #BABEC2",
  borderRadius: "4px",
  cursor: "pointer",
  height: "2.3rem",
  "&:hover": { boxShadow: 1 },
  color: "#696969",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 630,
  borderRadius: "20px",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  py: 2,
};

export default function SelectRootFolderModal({
  source,
  setFolder,
  setStep,
  handleClose,
  settingData,
  apiData,
  module,
  saveData,
  saveApiData,
  folderDetails,
  setFolderDetails,
  addFolder,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl,
  zuid,
}) {
  const [currentFolder, setCurrentFolder] = useState();
  const [selected, setSelected] = useState(settingData?.rootFolder);
  const [post, setPost] = useState([]);
  const [loadingPost, setLoadingPost] = useState(false);

  const [openNewFolderCreate, setOpenNewFolderCreate] = useState(false);
  const handleOpenNew = () => setOpenNewFolderCreate(true);
  const handleCloseNew = () => setOpenNewFolderCreate(false);

  const [workspace, setWorkspace] = useState(settingData.workspace || null);

  const [teamFolder, setTeamFolder] = useState(settingData?.teamFolder || null);

  useEffect(() => {
    async function fetchGoogledriveData() {
      setLoadingPost((prev) => true);
      try {
        const selectedFolderData = await ApiCall.getFoldersData(
          settingData?.rootFolder?.parent_id,
          connname,
          orgid,
          apikey,
          datacenterurl,
          settingUrl
        );
        if (selectedFolderData?.error) {
          return "";
        }
        setCurrentFolder(selectedFolderData?.data);
      } catch (error) {}
    }
    fetchGoogledriveData();
  }, []);

  useEffect(() => {
    if (currentFolder?.id) {
      if (apiData?.hasOwnProperty(currentFolder?.id)) {
        setLoadingPost((prev) => {
          return false;
        });
        return setPost((prev) => {
          return apiData?.[currentFolder?.id];
        });
      }

      async function fetchFolderItems() {
        try {
          const folderList = await ApiCall.getFoldersItem(
            currentFolder?.id,
            connname,
            orgid,
            apikey,
            datacenterurl,
            settingUrl,
            true
          );
          // ;

          if (folderList?.error) {
            setPost([]);
            return;
          }

          setPost((prev) => {
            return folderList?.data?.filter((item) => item?.is_folder);
          });

          setLoadingPost((prev) => {
            return false;
          });

          saveApiData({
            folderID: currentFolder?.id,
            folderResp: folderList?.data,
          });
        } catch (error) {
          // TODO Set Error
        }
      }
      fetchFolderItems();
    }
  }, [currentFolder?.id]);

  useEffect(() => {
    if (source == "ProcessHistoricData") {
      try {
        setFolder((prev) => {
          return selected;
        });
        setStep((prev) => 4);
      } catch (error) {}
    }
  }, [selected?.id]);

  let timer;
  let delay = 200;
  let prevent = false;

  const doClickAction = (data) => {
    setSelected((prev) => {
      return data;
    });
  };

  const doDoubleClickAction = (data) => {
    if (data?.id) {
      setLoadingPost((prev) => true);
      setCurrentFolder((prev) => {
        return data;
      });
      setSelected((prev) => data);
    }
  };

  const handleClick = (data) => {
    timer = setTimeout(function () {
      if (!prevent) {
        doClickAction(data);
      }
      prevent = false;
    }, delay);
  };

  const handleDoubleClick = (data) => {
    clearTimeout(timer);
    prevent = true;
    doDoubleClickAction(data);
  };

  return (
    <Box sx={{ mx: 3.5 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Box
          onClick={handleClose}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "35px",
            width: "35px",
            borderRadius: "50%",
            // p: 0.5,
            cursor: "pointer",
            "&:hover": { bgcolor: "rgba(0, 0, 0, 0.06)" },
          }}
        >
          <ClearIcon sx={{ color: "rgba(0, 0, 0, 0.54)", fontSize: 27 }} />
        </Box>
      </Box>
      <Box sx={{ mr: 2, mt: 1 }}>
        <Typography sx={{ fontWeight: 500, fontSize: 20, mb: 3 }}>
          Select Root Folder
        </Typography>

        <>
          <Button
            variant="outlined"
            disabled={currentFolder?.name == "My Drive" ? true : false}
            // size="small"
            sx={{
              cursor: "pointer",
              mb: 2,
              border: "none",
              textTransform: "none",
              color: "#1976D2",
              fontWeight: 500,
              fontSize: "14px",
              "&:hover": {
                border: "none",
              },
            }}
            startIcon={
              <ArrowBackIosIcon sx={{ height: "18px", width: "16px" }} />
            }
            onClick={async () => {
              if (folderDetails?.hasOwnProperty(currentFolder?.parent_id)) {
                return doDoubleClickAction(
                  folderDetails?.[currentFolder?.parent_id]
                );
              } else {
                try {
                  const foldersData = await ApiCall.getFoldersData(
                    currentFolder?.parent_id,
                    connname,
                    orgid,
                    apikey,
                    datacenterurl,
                    settingUrl
                  );

                  if (foldersData?.error) {
                    return "";
                  }

                  setFolderDetails({
                    folderID: currentFolder?.parent_id,
                    folderResp: foldersData?.data,
                  });

                  doDoubleClickAction(foldersData?.data);
                } catch (error) {}
              }
            }}
          >
            Previous Folder
          </Button>
          <Box
            sx={{
              overflowX: "hidden",
              overflowY: "auto",
              height: 250,
            }}
          >
            <Grid
              sx={{ py: 1 }}
              alignItems="center"
              container
              maxWidth="lg"
              spacing={2}
            >
              {loadingPost ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    mt: 18,
                  }}
                >
                  Please wait <CircularProgress sx={{ ml: 1 }} size={18} />
                </Box>
              ) : post.length ? (
                post.map((item, index) => (
                  <Grid key={index} item xs={4}>
                    <Box
                      sx={
                        item?.id == selected?.id
                          ? selectedStyle
                          : notSelectedStyle
                      }
                      onClick={() => {
                        doClickAction(item);
                      }}
                      onDoubleClick={() => {
                        handleDoubleClick(item);
                      }}
                    >
                      <FolderOutlinedIcon
                        sx={{
                          mx: 1.2,
                          // color: "#696969",
                        }}
                      />{" "}
                      <Typography
                        sx={{
                          pt: 0.1,
                          // color: "#696969",
                          display: "inline",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item?.name}
                      </Typography>
                    </Box>
                  </Grid>
                ))
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    fontWeight: 500,
                    mt: 3,
                  }}
                >
                  <img src={no_folder} height={100} alt="no folder icon" />

                  <Typography sx={{ fontWeight: "medium", mt: 2 }}>
                    This Folder is empty
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>
          <Divider sx={{ my: 3 }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              // mt: 3,
            }}
          >
            <Button
              onClick={() => {
                handleOpenNew();
                // handleClose();
              }}
              sx={{
                textTransform: "none",
                width: "11rem",
                borderRadius: "4px",
                height: "2.3rem",
                color: "#1976D2",
                border: "none",
                bgcolor: "transparent",
                fontWeight: 500,
                mr: 2,
                "&:hover": {
                  border: "none",
                  bgcolor: "#cfd7e6",
                },
              }}
              variant="outlined"
            >
              Create New Folder
            </Button>

            <Button
              onClick={() => {
                if (
                  selected?.parent_id != currentFolder?.id ||
                  selected?.id == undefined ||
                  selected?.id == null
                ) {
                  if (source == "ProcessHistoricData") {
                    try {
                      setFolder((prev) => {
                        return selected;
                      });
                      setStep((prev) => 4);
                    } catch (error) {}
                  }
                  setSelected((prev) => {
                    return currentFolder;
                  });
                }
                saveData({
                  key: "rootFolder",
                  response: selected,
                });
                saveData({
                  key: "workspace",
                  response: workspace,
                });
                saveData({
                  key: "teamFolder",
                  response: teamFolder,
                });
                saveData({
                  key: "teamFolder",
                  response: teamFolder,
                });
                //
                try {
                  setStep((prev) => 5);
                } catch (error) {
                  // 1c4f5f2a-92c6-4768-bff6-c8b478b1c839
                }
                handleClose();
              }}
              sx={{
                width: "8rem",
                borderRadius: "4px",
                textTransform: "none",
                height: "2.3rem",
                bgcolor: "#1976D2",
                fontWeight: 400,
              }}
              variant="contained"
              disabled={selected?.id ? false : true}
            >
              Select Folder
            </Button>
          </Box>
        </>
      </Box>

      <Modal
        open={openNewFolderCreate}
        onClose={handleCloseNew}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CreateNewFolderModal
            handleCloseNew={handleCloseNew}
            currentFolder={currentFolder}
            setPost={setPost}
            addFolder={addFolder}
            orgid={orgid}
            connname={connname}
            apikey={apikey}
            datacenterurl={datacenterurl}
            settingUrl={settingUrl}
          />
        </Box>
      </Modal>
    </Box>
  );
}
