import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
// import { setVerbosityLevel } from "pdfjs-dist/types/src/shared/util";

export default function Overview({
  handleRevokeGoogle,
  handleRevokeZoho,
  revokeLoadingZoho,
  revokeLoadingGoogle,
  authLoadingZoho,
  authLoadingGoogle,
  zohoAuth,
  googleAuth,
  handleAuthenticateZoho,
  setClientId,
  setClientSecret,
  handleAuthenticateGoogle,
  clientId = "",
  clientSecret = "",
  // dataCenterUrl,
}) {
  // const dataCenterUrlOptions = [
  //   "https://accounts.zoho.com",
  //   "https://accounts.zoho.eu",
  //   "https://accounts.zoho.com.au",
  //   "https://accounts.zoho.in",
  //   "https://accounts.zoho.com.cn",
  //   "https://accounts.zoho.jp",
  // ];
  // const [disable, setDisable] = useState(!dataCenterUrl);

  const [step, setStep] = useState(1);
  const { register, handleSubmit, setValue, watch, control, errors } =
    useForm();
  const watchAllFields = watch();

  useEffect(() => {
    setValue(clientId);
    setValue(clientSecret);
  }, [clientId, clientSecret]);

  const onSubmit = (data) => {
    setClientId((prev) => data?.Client_ID);
    setClientSecret((prev) => data?.Client_Secret);
    handleAuthenticateGoogle({
      clientId: data?.Client_ID,
      clientSecret: data?.Client_Secret,
    });
  };

  return (
    <Box
      sx={{
        pt: 1,
        width: "85%",
        mx: "auto",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "10px",
        mt: 2,
        bgcolor: "#fff",
      }}
    >
      <Box sx={{ px: 4 }}>
        <Typography
          sx={{
            mt: 2,
            mb: 1,
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: "24px",
            fontWeight: 600,
          }}
        >
          Authentication
        </Typography>
        <Divider />
        <br />
        {/* <Typography
          sx={{
            my: 1,
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: "bold",
          }}
        >
          Overview
        </Typography> */}
        <Box
          sx={{
            color: "rgba(0, 0, 0, 0.6)",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",

            "li:not(:last-child)": {
              margin: "0 0 10px 0",
            },
          }}
        >
          Google Drive is a standalone app which needs to integrate with ZOHO
          using Rest API. To do Read/Create/Edit/Delete tasks in Google Drive
          you need to authenticate the Google Drive and ZOHO CRM to make
          Widgets/Workflow functional.
        </Box>
        {/* <Typography
          sx={{
            my: 1,
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: "bold",
          }}
        >
          Select Data Center URL
        </Typography> */}
        {/* <Box
          sx={{
            color: "rgba(0, 0, 0, 0.6)",
            fontSize: "16px",
            fontWeight: 400,
            linetHeight: "24px",
          }}
        >
          Based on diffent ZOHO Data Center you need to select appropriate Data
          Center URL and Hit Authenticate and follow the steps to proceed.
        </Box> */}
      </Box>
      <Box sx={{ mt: 3, display: "flex", p: 4 }}>
        <Box sx={{ width: "50%" }}>
          {
            <Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="Client_ID"
                  control={control}
                  defaultValue={clientId}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      sx={{ width: 463 }}
                      id="outlined-required"
                      label="Client ID"
                      variant="outlined"
                      {...field}
                      required
                    />
                  )}
                />
                <br /> <br />
                <Controller
                  name="Client_Secret"
                  defaultValue={clientSecret}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      id="outlined-required"
                      size="small"
                      sx={{ width: 463 }}
                      label="Client Secret"
                      variant="outlined"
                      {...field}
                      required
                    />
                  )}
                />
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  {googleAuth ? (
                    <Button
                      disabled={revokeLoadingGoogle}
                      onClick={handleRevokeGoogle}
                      sx={{
                        mt: 2.5,
                        mb: 2,
                        mr: 2,
                        textTransform: "none",
                        width: "14rem",
                        borderRadius: "8px",
                        height: "2.9rem",
                        bgcolor: "#fc493d",
                        "&:hover": { bgcolor: "#fa3023" },
                      }}
                      variant="contained"
                    >
                      {revokeLoadingGoogle ? (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          loading{" "}
                          <CircularProgress
                            sx={{ color: "white", ml: 1 }}
                            size={17}
                          />{" "}
                        </Box>
                      ) : (
                        "Revoke Google Drive"
                      )}
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      disabled={
                        watchAllFields?.Client_ID &&
                        watchAllFields?.Client_Secret &&
                        !authLoadingGoogle
                          ? false
                          : true
                      }
                      // disabled={authLoadingGoogle}
                      sx={{
                        mt: 2.5,
                        mb: 2,
                        mr: 2,
                        textTransform: "none",
                        width: "14rem",
                        borderRadius: "8px",
                        height: "2.9rem",
                        bgcolor: "#1976D2",
                      }}
                      variant="contained"
                    >
                      {authLoadingGoogle ? (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          loading
                          <CircularProgress
                            sx={{ color: "white", ml: 1 }}
                            size={17}
                          />{" "}
                        </Box>
                      ) : (
                        "Authenticate Google"
                      )}
                    </Button>
                  )}

                  {zohoAuth ? (
                    <Button
                      disabled={revokeLoadingZoho}
                      onClick={handleRevokeZoho}
                      sx={{
                        mt: 2.5,
                        mb: 2,
                        textTransform: "none",
                        width: "14rem",
                        borderRadius: "8px",
                        height: "2.9rem",
                        bgcolor: "#fc493d",
                        "&:hover": { bgcolor: "#fa3023" },
                      }}
                      variant="contained"
                    >
                      {revokeLoadingZoho ? (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          loading
                          <CircularProgress
                            sx={{ color: "white", ml: 1 }}
                            size={17}
                          />{" "}
                        </Box>
                      ) : (
                        "Revoke ZOHO CRM"
                      )}
                    </Button>
                  ) : (
                    <Button
                      disabled={authLoadingZoho || !googleAuth}
                      onClick={() => {
                        handleAuthenticateZoho();
                        // setShow(true);
                      }}
                      sx={{
                        mt: 2.5,
                        mb: 2,

                        textTransform: "none",
                        width: "14rem",
                        borderRadius: "8px",
                        height: "2.9rem",
                        bgcolor: "#1976D2",
                      }}
                      variant="contained"
                    >
                      {authLoadingZoho ? (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          loading{" "}
                          <CircularProgress
                            sx={{ color: "white", ml: 1 }}
                            size={17}
                          />{" "}
                        </Box>
                      ) : (
                        "Authenticate ZOHO CRM"
                      )}
                    </Button>
                  )}
                </div>
              </form>
            </Box>
          }
        </Box>
        <Box
          sx={{
            width: "50%",
            bgcolor: "#d9ebff",
            p: 2,
            height: 400,
            display: "grid",
          }}
        >
          <Typography>Follow This Steps one by one: </Typography>
          <Box sx={{ height: 300 }}>
            {step === 1 && (
              <Box sx={{ my: 1, alignSelf: "start" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Step -1: Create a Google Drive project
                </Typography>
                <Typography sx={{ mt: 2 }}>
                  Open URL:{" "}
                  <a href="https://console.cloud.google.com" target="_blank">
                    https://console.cloud.google.com
                  </a>
                  {` (If you are not signed in. Please signed in) in a new tab`}
                </Typography>
                <Typography>
                  1b. At the top-left, click (Menu Icon){" "}
                  <span style={{ fontWeight: "bold" }}>
                    {` menu > IAM & Admin > Create a Project.`}
                  </span>
                </Typography>
                <Typography>
                  1c. In the Project Name field, enter a descriptive name for
                  your project and press Create button.
                </Typography>
                <Typography>
                  1d. After creating project{" "}
                  <span style={{ fontWeight: "bold" }}>make sure</span> that you
                  select the newly created project from the dropdown at top app
                  bar and after selecting your newly created project name is
                  visible adjacent to Google Cloud.
                </Typography>
              </Box>
            )}
            {step === 2 && (
              <Box sx={{ my: 1, alignSelf: "start" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Step -2: Enable Google Drive Api Library
                </Typography>
                <Typography sx={{ mt: 2 }}>
                  2a. At the top-left, click (Menu Icon)
                  <span style={{ fontWeight: "bold" }}>
                    {` menu > APIs and services > Library`}
                  </span>
                </Typography>
                <Typography>
                  2b. Enable{" "}
                  <span style={{ fontWeight: "bold" }}>'Google Drive API'</span>{" "}
                  library under Google Workspace section (If you can't find.
                  Please search for Google Drive API) and open it.
                </Typography>
                <Typography>
                  2c. Make the library enable and to be confirmed, see
                  notification.
                </Typography>
              </Box>
            )}
            {step === 3 && (
              <Box sx={{ my: 1, alignSelf: "start" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Step -3: Configure Consent Screen
                </Typography>
                <Typography sx={{ mt: 2 }}>
                  3a. At the top-left, click (Menu Icon)
                  <span style={{ fontWeight: "bold" }}>
                    {` menu > APIs and services > OAuth consent screen`}
                  </span>
                </Typography>
                <Typography>
                  3b. Set <span style={{ fontWeight: "bold" }}>User Type</span>{" "}
                  as <span style={{ fontWeight: "bold" }}>external</span> and
                  press create button.
                </Typography>
                <Typography>
                  3c. Fill <span style={{ fontWeight: "bold" }}>App name</span>,{" "}
                  <span style={{ fontWeight: "bold" }}>User Support Email</span>{" "}
                  and{" "}
                  <span style={{ fontWeight: "bold" }}>Developer Email</span>
                  field then press 'Save and Continue' button.
                </Typography>
                <Typography>
                  3d. You don't need to add anything in scope section. Just
                  press 'Save and Continue' button.
                </Typography>
                <Typography>
                  3e. In Test users section add your self(email of the google
                  drive account) as Test user by pressing 'ADD USERS' button.
                  After adding test user press 'Save and Continue' button.
                </Typography>
                <Typography>
                  3f. Check again every details in Summary section(If you need
                  to edit or change press edit button). If every information is
                  okay press{" "}
                  <span style={{ fontWeight: "bold" }}>
                    'BACK TO DASHBOARD'
                  </span>{" "}
                  button.
                </Typography>
              </Box>
            )}
            {step === 4 && (
              <Box sx={{ my: 1, alignSelf: "start" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Step -4: Create Credentials
                </Typography>
                <Typography sx={{ mt: 2 }}>
                  4a. At the top-left, click (Menu Icon)
                  <span style={{ fontWeight: "bold" }}>
                    {` menu > APIs and services > Credentials`}
                  </span>
                </Typography>
                <Typography>
                  4b. Press{" "}
                  <span style={{ fontWeight: "bold" }}>
                    '+CREATE CREDENTIALS'
                  </span>{" "}
                  button at top and select OAuth client ID.
                </Typography>
                <Typography>
                  4c. Select Application type as{" "}
                  <span style={{ fontWeight: "bold" }}>'Web application'</span>{" "}
                  and add Authorized redirect URIs as:
                  <a
                    href={`${process.env.REACT_APP_ADMIN_SERVER_URL}/auth/google/callback`}
                    target="_blank"
                  >
                    {`${process.env.REACT_APP_ADMIN_SERVER_URL}/auth/google/callback`}
                  </a>
                </Typography>
                <Typography>
                  4d. Press 'CREATE' button after pressing button in pop up
                  section you will get your Client-ID and Client-Secret.{" "}
                  <span style={{ fontWeight: "bold" }}>Download</span> it for
                  later use.
                </Typography>
                <Typography>
                  4e. Copy that Client-ID and Client-Secret paste it here. Next
                  authenticate (if you don’t see any google authentication pop
                  up, please disable your pop up blocker)
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignSelf: "end",
            }}
          >
            <Button
              disabled={step === 1}
              onClick={() => setStep(step - 1)}
              variant="contained"
              sx={{ mr: 2, width: 150 }}
            >
              Previous Step
            </Button>
            <Button
              disabled={step === 4}
              onClick={() => setStep(step + 1)}
              variant="contained"
              sx={{ width: 150 }}
            >
              Next Step
            </Button>
          </Box>
        </Box>
      </Box>
      {/* <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={dataCenterUrlOptions}
          defaultValue={
            dataCenterUrl
              ? "https://accounts.zoho." +
                dataCenterUrl?.split("https://www.zohoapis.")?.[1]
              : ""
          }
          onChange={(e, v) => {
            if (v) {
              // setDataCenterUrl(v);
              setDisable(false);
            } else {
              // setDataCenterUrl();
              setDisable(true);
            }
          }}
          fullWidth
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Data Center Url"
              InputLabelProps={{ shrink: true }}
            />
          )}
        /> */}
      {/* </Box> */}
    </Box>
  );
}
